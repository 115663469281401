<template>
  <div>
    <a-card :body-style="{ padding: '24px 32px' }" :bordered="false">
      <div class="head-area">
        <div class="pull-left">
          <span>主题：</span>
          <span>{{ info.title }}</span>
        </div>
        <div class="pull-right">
          <a-popconfirm v-if="workOrderStatus !== 'Close'" title="确定关闭该工单?" @confirm="() => closeWorkOrder(info.id)">
            <a href="javascript:;">关闭工单</a>
          </a-popconfirm>
        </div>
      </div>
      <div class="status-area">
        <span>状态：</span>
        <span v-if="info.status === 'AdminReply'" class="reply">已回复</span>
        <span v-else-if="info.status === 'Open'" class="open">进行中</span>
        <span v-else-if="info.status === 'UserReply'" class="open">待回复</span>
        <span v-else class="close">已关闭</span>
      </div>
      <div style="line-height: 32px;">
        <span class="child-title">问题描述：</span>
        <span>{{ info.content }}</span>
      </div>
      <div class="attachment-list" v-if="infoAttachmentList.length > 0" style="text-align: left;">
        <img class="attachment-img" v-for="(val, ii) in infoAttachmentList" :key="ii" :src="val.url_base64" alt="" />
      </div>
      <hr class="division" />
      <div v-if="!loading && latest_reply !== null">
        <div class="child-title">最新回复</div>
        <div class="latest-reply">
          <div class="dialogue-content">
            <div class="pull-left head-img">
              <img src="@/assets/images/header3.png" alt="头像" />
            </div>
            <div class="pull-left details-info">
              <div class="date-info">
                <div class="pull-left">
                  {{ latest_reply.nick_name }}
                </div>
                <div class="pull-left" style="margin-left: 10px">
                  {{ latest_reply.create_time | dayjs }}
                </div>
              </div>
              <div class="content">
                {{ latest_reply.content }}
              </div>
              <div class="attachment-list" v-if="latestReplyAttach.length > 0" style="text-align: left;">
                <img class="attachment-img" v-for="(val, ii) in latestReplyAttach" :key="ii" :src="val.url_base64"
                  alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="child-title">暂无最新回复</div>
      <div class="input-area">
        <a-textarea id="reply_content" v-model="reply_content" placeholder="编辑您的回复消息"></a-textarea>
      </div>
      <div class="post-area">
        <div class="pull-left">
          <a-upload action="/api/admin/file/uploadProtectedImage" name="image" :multiple="true" list-type="picture"
            :headers="headers" accept="image/png,image/jpeg" :file-list="enclosureFileList" @change="uploadChange">
            <a-button>
              <a-icon type="upload" /> 点击上传
            </a-button>
          </a-upload>
        </div>
        <div class="pull-right">
          <a-button type="primary" @click="postReplyHandle" :loading="state.postBtn" size="large">提交</a-button>
        </div>
      </div>
      <div class="child-title" style="margin-top: 15px;">沟通记录</div>
      <div class="dialogue-area" v-if="!loadAttachment && worksheetReply">
        <div class="dialogue-content" v-for="(item, index) in worksheetReply" :key="index">
          <div v-if="item.admin === ''" class="user-reply">
            <div class="pull-left head-img">
              <img src="@/assets/images/header1.png" alt="头像" />
            </div>
            <div class="pull-left details-info">
              <div class="date-info">
                <div class="pull-left">
                  {{ item.nick_name }}
                </div>
                <div class="pull-left" style="margin-left: 10px;">
                  {{ item.create_time | dayjs }}
                </div>
              </div>
              <div class="content">
                {{ item.content }}
              </div>
              <div class="attachment-list" v-if="item.attachment_list">
                <img class="attachment-img" v-for="(attach, i) in item.attachment_list" :key="i" :src="attach.url_base64"
                  alt="" />
              </div>
            </div>
          </div>
          <div v-else class="admin-reply">
            <div class="pull-right head-img">
              <img src="@/assets/images/header2.png" alt="头像" />
            </div>
            <div class="pull-right details-info">
              <div class="date-info">
                <div class="pull-right admin-name">
                  {{ item.admin }}
                </div>
                <div class="pull-right send-time">
                  {{ item.create_time | dayjs }}
                </div>
              </div>
              <div class="content">
                {{ item.content }}
              </div>
              <div class="attachment-list" v-if="item.attachment_list">
                <img class="attachment-img" v-for="(attach, i) in item.attachment_list" :key="i" :src="attach.url_base64"
                  alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </a-card>
  </div>
</template>
<script>
import store from '@/store/'

export default {
  name: 'UserWorksheetDetails',
  components: {},
  props: {
    id: {
      required: true,
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      headers: {
        authorization: store.getters.token
      },
      loading: true,
      state: {
        postBtn: false,
        closeBtn: false
      },
      info: {},
      infoAttachmentList: [],
      worksheet_reply: [],
      worksheetReply: [],
      latest_reply: {},
      latestReplyAttach: [],
      // 上传附件图片
      attachment: [],
      reply_content: '',
      workOrderStatus: '',
      enclosureFileList: [],
      loadAttachment: true
    }
  },
  created() {
    this.loadData(this.id)
  },
  watch: {
    info(val) {
      const winThis = this
      // 初次提交的附件
      if (val.attachment_list !== undefined) {
        const attachmentList = val.attachment_list
        // 循环列表数据
        attachmentList.forEach((vvv, index) => {
          const url = vvv.url
          // 居然成功了，循环获取图片的base64
          new Promise((resolve, reject) => {
            winThis
              .$http({
                url: url,
                method: 'get'
              })
              .then(res => {
                vvv.url_base64 = res
                resolve(vvv)
              })
          }).then(re => {
            winThis.$set(winThis.infoAttachmentList, index, re)
          })
        })
      }
    },
    latest_reply(val) {
      const winThis = this
      // 初次提交的附件
      if (val.attachment_list !== undefined) {
        const attachmentList = val.attachment_list
        // 循环列表数据
        attachmentList.forEach((vvv, index) => {
          const url = vvv.url
          // 居然成功了，循环获取图片的base64
          new Promise((resolve, reject) => {
            winThis
              .$http({
                url: url,
                method: 'get'
              })
              .then(res => {
                vvv.url_base64 = res
                resolve(vvv)
              })
          }).then(re => {
            winThis.$set(winThis.latestReplyAttach, index, re)
          })
        })
      }
    },
    // 回复数据，需要处理其中的附件用于显示
    worksheet_reply(list) {
      this.loadAttachment = true
      const winThis = this
      // 循环列表数据
      list.forEach((item, index) => {
        // 存在附件列表
        if (item.attachment_list !== undefined) {
          var attachmentList = item.attachment_list
          attachmentList.forEach((i, k) => {
            const url = i.url
            // 居然成功了，循环获取图片的base64
            new Promise((resolve, reject) => {
              winThis
                .$http({
                  url: url,
                  method: 'get'
                })
                .then(res => {
                  i.url_base64 = res
                  resolve(i)
                })
            }).then(res => {
              winThis.$set(winThis.worksheetReply, index, item)
            })
          })
        } else {
          // 不存在附件列表
          winThis.$set(winThis.worksheetReply, index, item)
        }
      })
      this.loadAttachment = false
    }
  },
  methods: {
    loadData(id) {
      this.loading = true
      this.$http({
        url: 'admin/worksheet/info',
        method: 'get',
        params: {
          id: id
        }
      }).then(res => {
        this.loading = false
        if (res.status === 'success') {
          const data = res.data
          this.info = data.info
          this.worksheet_reply = data.info.worksheet_reply
          this.latest_reply = data.latest_reply
          this.workOrderStatus = data.info.status
        } else {
          this.$notification.error({
            message: '系统繁忙，请刷新重试',
            description: ''
          })
        }
      })
    },
    uploadChange(e) {
      const attachment = []
      e.fileList.forEach((item, index, array) => {
        if (item.response !== undefined && item.response.status === 'success') {
          attachment.push(item.response.data)
        }
      })
      this.attachment = attachment
      this.enclosureFileList = e.fileList
      if (Array.isArray(e)) {
        return e
      }
      return e && e.fileList
    },
    postReplyHandle() {
      var contentEle = document.getElementById('reply_content')
      if (this.reply_content === '') {
        contentEle.focus()
        return false
      }
      this.state.postBtn = true
      this.$http({
        url: 'admin/worksheet/reply',
        method: 'post',
        data: {
          id: this.id,
          content: this.reply_content,
          attachment: this.attachment
        }
      }).then(res => {
        this.state.postBtn = false
        if (res.status === 'success') {
          this.$notification.success({
            message: '成功',
            description: '工单回复成功'
          })
          this.reply_content = ''
          this.enclosureFileList = []
          this.attachment = []
          // 重新加载数据
          this.loadData(this.id)
        } else {
          this.$notification.error({
            message: '系统繁忙，请刷新重试',
            description: ''
          })
        }
      })
    },
    closeWorkOrder(id) {
      this.state.closeBtn = true
      this.$http({
        url: 'admin/worksheet/close',
        method: 'post',
        data: {
          id: id
        }
      }).then(res => {
        this.state.closeBtn = false
        if (res.status === 'success') {
          this.$notification.success({
            message: '成功',
            description: res.message
          })
        } else {
          this.$notification.error({
            message: '失败',
            description: res.message
          })
        }
      })
    }
  }
}
</script>
<style lang="stylus" scoped>
.head-area {
  overflow: hidden;
  margin-top: 15px;

  .pull-left {
    span:first-child {
      font-family: MicrosoftYaHei;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      line-height: 25px;
      letter-spacing: 0px;
      color: #999999;
    }

    span:nth-child(2) {
      font-family: MicrosoftYaHei;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      line-height: 25px;
      letter-spacing: 0px;
      color: #333333;
    }
  }
}

.status-area {
  span:first-child {
    font-family: MicrosoftYaHei;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    line-height: 25px;
    letter-spacing: 0px;
    color: #999999;
  }

  span:nth-child(2) {
    font-family: MicrosoftYaHei;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    line-height: 25px;
    letter-spacing: 0px;
  }

  .reply {
    color: #5bc979;
  }

  .close {
    color: #333;
  }

  .open {
    color: #5bc979;
  }
}

.input-area {
  margin-top: 15px;

  textarea {
    width: 100%;
    height: 130px;
    border-radius: 4px;
  }
}

.post-area {
  overflow: hidden;
  margin-top: 20px;

  .pull-left {
    width: 200px;
  }

  .pull-right {
    button {
      width: 100px;
      height: 40px;
    }
  }
}

hr.division {
  height: 1px;
  background-color: #f3f3f3;
  border: none;

  margin: 15px 0;
}

.child-title {
  font-family: MicrosoftYaHei;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  line-height: 25px;
  letter-spacing: 0px;
  color: #999999;
}

.latest-reply {
  margin-top: 15px;
}

.dialogue-content {
  overflow: hidden;
  padding: 10px 10px 20px 20px;
  background-color: #f7f7f7;
  border-radius: 10px;

  .admin-reply {
    overflow: hidden;
    background-color: #f0f0f0;
    padding: 5px;
    border-radius: 10px;

    .content {
      text-align: right;
    }

    .attachment-list {
      margin-top: 10px;
      text-align: right;
      .attachment-img {
        margin-left: 10px;
      }
    }
  }

  .user-reply {
    background-color: #f7f7f7;
    padding: 5px;
    border-radius: 10px;

    .attachment-list {
      margin-top: 10px;
      text-align: left;
      .attachment-img {
        margin-right: 10px;
      }
    }
  }

  .head-img {
    width: 33px;
    img {
      width: 33px;
      height: 33px;
      border-radius: 50%;
    }
  }

  .details-info {
    max-width: 720px;
    margin-left: 10px;

    .date-info {
      overflow: hidden;

      font-family: MicrosoftYaHei;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      line-height: 32px;
      letter-spacing: 0px;
      color: #999999;

      .admin-name {
        margin-right: 10px;
      }

      .send-time {
        margin-right: 10px;
      }
    }

    .content {
      font-family: MicrosoftYaHei;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      line-height: 24px;
      letter-spacing: 0px;
      color: #333333;
    }
  }
}

.dialogue-area {
  margin-top: 15px;
  background-color: #f7f7f7;
  border-radius: 10px;
  // height: 274px;
  height: 350px;
  overflow-y: auto;

  padding-top: 20px;
  padding-bottom: 20px;
}

.attachment-list {
  margin-top: 10px;
  text-align: right;
  .attachment-img {
    margin-left: 10px;
    height: 60px;
  }
}
</style>
